import React from "react";
import LottieAnimation from "../utils/LottieAnimation";

const Test = () => {
  return (
    <div>
      <LottieAnimation />
    </div>
  );
};

export default Test;
